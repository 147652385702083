#sendMessageButton {
    width: 100%;
    padding: 1.17rem 2.5rem;
}

#mainNav {
    background: #fff
}

.navbar-brand {
    padding-top: 0;
}

#mainNav.navbar-shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #fff;
    box-shadow: 0 -8px 14px 0 #333333;
}

section#contact {
    background-image: none;
    background-color: #f3f3f3;
}

#mainNav .navbar-nav .nav-item .nav-link {
    color: #000;
}

section#contact .section-heading {
    color: #000;
}

.recomend {
    padding: 3rem 0;
}

header.masthead {
    background: #333;
    padding-top: 0;
    padding-bottom: 0;
}

.head-text {
    position: absolute;
    z-index: 20;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 35%;
}

.contact {
    font-size: 16px;
    margin: 5px 10px;
    font-weight: 600;
}

@media (max-width: 900px) {
    .col-mob {
        font-size: 10px
    }
}

@media (max-width: 640px) {
    .modal-body .table td:first-of-type {
        display: none;
    }



}

@media (min-width: 900px) {
    .head-slide {
        height: 80vh !important;
        background-size: auto 85% !important;
    }

}

#carbon .image, #bab .image, #hleb .image, #stan .image {
    width: 100%;
    height: 400px;
    background-size: cover !important;
    background-position: center !important;
}

#plates .image {
    width: 100%;
    height: 700px;
    background-size: cover !important;
    background-position: center !important;
}

.modal-dialog {
    max-width: 90vw !important;
}

html {
    overflow-x: hidden;
}

.head-slide {
    width: 100%;
    height: 350px;
    background-position: bottom;
    background-size: 100%;
    background-position: center 100px;
    background-repeat: no-repeat;
    background-color: #fff;
}

.head-text {
    display: none;
}

form.contact-form .form-group {
    margin-bottom: 1.5rem;
}

form.contact-form .form-group input,
form.contact-form .form-group textarea {
    padding: 1.25rem;
}

form.contact-form .form-group input.form-control {
    height: auto;
}

form.contact-form .form-group-textarea {
    height: 100%;
}

form.contact-form .form-group-textarea textarea {
    height: 100%;
    min-height: 10rem;
}

form.contact-form p.help-block {
    margin: 0;
}

form.contact-form .form-control:focus {
    border-color: #f7922a;
    box-shadow: none;
}

form.contact-form ::-webkit-input-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}

form.contact-form :-moz-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}

form.contact-form ::-moz-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}

form.contact-form :-ms-input-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../assets/close.svg") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}