.plates-holder {
    width: 100%;
}

.plates-row {
    display: flex;
}

.plates-row + .plates-row {
    margin-top: 20px;
}

.plates-selector {
    display: flex;
}

.plates-selector__item {
    width: 100px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    margin-right: 10px;
    cursor: pointer;
}

.plates-selector__item.active {
    border-color: orange;
}

.plates-preview {
    width: 100%;
    margin-right: 20px;
}

.plates-preview__image {
    height: 400px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
}

.plates-description {
    width: 100%;
    text-align: left;
}

.plates-article {
    margin: 10px 0;
    font-size: 1.2em;
    font-weight: 600;
}

.plates-price {
    width: 180px;
}

.plates-price__alk,
.plates-price__pvh {
    display: flex;
    justify-content: space-between;
}

.plates-price__alk .price,
.plates-price__pvh .price {
    font-style: italic;
}

.plates-works {
    width: 100%;
    display: flex;
    justify-content: center;
}

.plates-works--image {
    width: 50%;
    height: 560px;
    background-position: top center;
    background-size: cover;
}

.calculator {
    display: grid;
    grid-template-columns: 410px 1fr;
    grid-gap: 30px;
}
.calculator-variants {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 10px;
}

.calculator-variant img {
    width: 100%;
}

.calculator-variant {
    border: 1px solid #d7d7d7;
    cursor: pointer;
    min-height: 200px;
    display: flex;
    align-items: center;
}

.calculator-variant.active {
    border: 1px solid #d77a28;
}

.calculator-types {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:10px;
    margin-bottom: 20px;
}

.calculator-type {
    border: 1px solid;
    padding: 5px 25px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
}

.calculator-type.active {
    font-weight: bold;
    background: #e6f3d5;
}


.calculator-image img {
    width: 100%;
    border: 1px solid #d7d7d7;
    min-width: 600px;
    max-height: 450px;
    object-fit: cover;
}

.calculator-settings {
    margin-top: 20px;
}

.calculator-preview-street {
    position: absolute;
}

.calculator-preview-house {
    position: absolute;
}

.calculator-image {
    position: relative;
}

.calculator {
    margin-top: 50px;
}

.calculator-image.red {
    background: #7a231c;
    color: #7a231c;
}

.calculator-image.green {
    background: #017536;
    color: #017536;
}

.calculator-image.blue {
    background: #0082c8;
    color: #0082c8;
}

.calculator-image.deep-blue {
    background: #24407f;
    color: #24407f;
}

.calculator-image.naviblue {
    background: #2a2f66;
    color: #2a2f66;
}

.calculator-image.black {
    background: black;
    color: black;
}

.calculator-image.graphite {
    background: #424242;
    color: #424242;
}

.calculator-image.cream {
    background: #fff7b2;
    color: #fff7b2;
}

.calculator-image.peach {
    background: #fbd5a6;
    color: #fbd5a6;
}

.calculator-image.brown {
    background: #4b3528;
    color: #4b3528;
}

.calculator-image.cream img,
.calculator-image.peach img
.calculator-image.white img {
    /*filter: invert(1);*/
}

.plate-color {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.plate-color--red {
    background-color: #7a231c;
}

.plate-color--green {
    background-color: #017536;
}

.plate-color--blue {
    background-color: #0082c8;
}

.plate-color--deep-blue {
    background-color: #24407f;
}

.plate-color--naviblue {
    background-color: #2a2f66;
}

.plate-color--black {
    background-color: black;
}

.plate-color--graphite {
    background-color: #424242;
}

.plate-color--cream {
    background-color: #fff7b2;
}

.plate-color--peach {
    background-color: #fbd5a6;
}

.plate-color--white {
    background-color: white;
    border: 1px solid #ccc;
}

.plate-color--brown {
    background-color: #4b3528;
}

.plate-colors {
    display: flex;
}

.calculator-preview-house,
.calculator-preview-street {
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;
}

.B3, .B4, .B10, .B11 {
    font-family: serif;
}

.B1 .calculator-preview-street {
    top: 210px;
    left: 59px;
    width: 560px;
    height: 80px;
    text-transform: uppercase;

}

.B2 .calculator-preview-street {
    top: 214px;
    left: 168px;
    width: 340px;
    height: 60px;
    text-transform: uppercase;
}

.B3 .calculator-preview-street {
    top: 214px;
    left: 168px;
    width: 340px;
    height: 60px;
}

.B7 .calculator-preview-street {
    top: 303px;
    left: 168px;
    width: 340px;
    height: 60px;
    text-transform: uppercase;
}

.B7 .calculator-preview-house {
    top: 151px;
    left: 168px;
    width: 340px;
    height: 60px;
}

.B8 .calculator-preview-street {
    top: 216px;
    left: 48px;
    width: 460px;
    height: 60px;
}

.B8 .calculator-preview-house {
    top: 216px;
    left: 538px;
    width: 100px;
    height: 60px;
    color: inherit;
}

.B9 .calculator-preview-street {
    top: 216px;
    left: 168px;
    width: 265px;
    height: 60px;
}

.B9 .calculator-preview-house {
    top: 216px;
    left: 456px;
    width: 60px;
    height: 60px;
    color: inherit;
}

.B3 .calculator-preview-street {
    top: 181px;
    left: 65px;
    width: 550px;
    height: 60px;
}

.B3 .calculator-preview-house {
    top: 288px;
    left: 246px;
    width: 180px;
    height: 60px;
    color: inherit;
}

.B4 .calculator-preview-street {
    top: 190px;
    left: 175px;
    width: 330px;
    height: 60px;
}

.B4 .calculator-preview-house {
    top: 258px;
    left: 293px;
    width: 95px;
    height: 60px;
    color: inherit;
}

.B10 .calculator-preview-street {
    top: 238px;
    left: 55px;
    width: 567px;
    height: 61px;
}

.B10 .calculator-preview-house {
    top: 159px;
    left: 270px;
    width: 137px;
    height: 61px;
}

.B11 .calculator-preview-street {
    top: 236px;
    left: 170px;
    width: 337px;
    height: 61px;
}

.B11 .calculator-preview-house {
    top: 183px;
    left: 305px;
    width: 67px;
    height: 61px;
}

.B14 .calculator-preview-street {
    top: 296px;
    left: 55px;
    width: 568px;
    height: 60px;
}

.B14 .calculator-preview-house {
    top: 127px;
    left: 250px;
    width: 189px;
    height: 60px;
}

.B15 .calculator-preview-street {
    top: 270px;
    left: 175px;
    width: 328px;
    height: 60px;
}

.B15 .calculator-preview-house {
    top: 175px;
    left: 280px;
    width: 119px;
    height: 60px;
}

/**/

.B5 .calculator-preview-street {
    top: 198px;
    left: 65px;
    width: 546px;
    height: 60px;
    text-transform: uppercase;

}

.B5 .calculator-preview-house {
    top: 278px;
    left: 286px;
    width: 101px;
    height: 60px;
}

.B6 .calculator-preview-street {
    top: 202px;
    left: 191px;
    width: 322px;
    height: 48px;
    text-transform: uppercase;
}

.B6 .calculator-preview-house {
    top: 244px;
    left: 326px;
    width: 56px;
    height: 60px;
}

.B12 .calculator-preview-street {
    top: 198px;
    left: 65px;
    width: 546px;
    height: 60px;
}

.B12 .calculator-preview-house {
    top: 278px;
    left: 286px;
    width: 101px;
    height: 60px;
}

.B13 .calculator-preview-street {
    top: 202px;
    left: 191px;
    width: 322px;
    height: 48px;
}

.B13 .calculator-preview-house {
    top: 240px;
    left: 334px;
    width: 56px;
    height: 60px;
}

.calcualator-settings-row {
    display: grid;
    grid-template-columns: 1fr 230px;
}

.plate-material {
    padding: 4px;
    border: 1px solid;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.plate-materials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    font-size: 14px;
}

.plate-material.active {
    background: #e6f3d5;
    font-weight: bold;
}

.calcualator-settings-row {
    display: grid;
    grid-template-columns: 1fr 280px;
}

.plate-material {
    padding: 4px;
    border: 1px solid;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.plate-materials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    font-size: 14px;
}

.plate-material.active {
    background: #e6f3d5;
    font-weight: bold;
}

.form-group.calculator-price {
    font-size: 30px;
    margin-top: 30px;
}

.form-group.calculator-price label {
    margin-right: 10px;
    font-size: 18px;
}

.calculator-checkout {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.details {
    display: flex;
}

.details > div {
    margin: 0px 30px 15px 2px;
}

.calculator-size {
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: bold;
}

@media (max-width: 685px) {
    #calculator .container {
        margin: 0 0px;
        padding: 0 10px;
        max-width: 100%;
    }

    .calculator-type {
        padding: 5px 15px;
    }

    .calculator {
        display: flex;
        flex-direction: column;
    }

    .calculator-variants {
        grid-template-columns: 1fr 1fr;
    }

    .calculator-image {
        margin-top: 20px;
    }


    .calcualator-settings-row {
        grid-template-columns: 1fr;
    }

    .calculator-checkout {
        justify-content: flex-start;
    }
}